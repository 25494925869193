
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.steps-wrapper {
    display: flex;
    will-change: transform;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    height: auto; // internet explorer fallback
    height: fit-content;
    justify-content: stretch;
    .stepBlock {
        width: 100%;
        flex-shrink: 0;
        position: relative;
        z-index: 50;
        height: auto; // internet explorer fallback
        height: fit-content;
        padding: 0 2px;
    }
}

.inner {
    flex-grow: 1;
    padding-bottom: 20px;
    overflow: hidden;
}

.custom-progress {
    margin-right: 15px;
    margin-left: 15px;
}

.title {
    margin-bottom: 1rem;
    @include handleAllBreakpoints(
        "font-size",
        "categoryStepTitleSize",
        "24px::var(--global-form-typography-firstStepTitle-size)"
    );
    @include handleAllBreakpoints(
        "font-weight",
        "categoryStepTitleWeight",
        "var(--global-form-typography-firstStepTitle-weight)"
    );
    @include handleAllBreakpoints(
        "color",
        "categoryStepTitleColor",
        "var(--global-form-typography-firstStepTitle-color)"
    );
    @include handleAllBreakpoints(
        "text-align",
        "categoryStepTitleAlign",
        "var(--global-form-typography-firstStepTitle-align)"
    );
}

.description {
    text-align: left;
    font-weight: 300;
    margin-bottom: 2rem;
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }

    @include handleAllBreakpoints(
        "font-size",
        "categoryDescriptionSize",
        "var(--global-form-typography-description-size)"
    );
    @include handleAllBreakpoints(
        "font-weight",
        "categoryDescriptionWeight",
        "var(--global-form-typography-description-weight)"
    );
    @include handleAllBreakpoints(
        "color",
        "categoryDescriptionColor",
        "var(--global-form-typography-description-color)"
    );
    @include handleAllBreakpoints(
        "text-align",
        "categoryDescriptionAlign",
        "var(--global-form-typography-description-align)"
    );
}

.errorMessage {
    color: red;
    font-size: 12px;
    margin: 10px;
    text-align: left;
    @include md {
        text-align: center;
    }
}

.back-button {
    left: 0;
    bottom: -20px;
}

.caret {
    // absolute md:relative bottom-2 right-1 w-5 md:w-10 mt-4
    position: absolute;

    bottom: 0.5rem;
    right: 0.25rem;
    width: 1.25rem;
    margin-top: 1rem;

    @include md {
        width: 2.5rem;
        position: relative;
    }

    svg {
        polyline {
            stroke: #767676;
            @include md {
                stroke: black;
            }
        }
    }
}

.loading {
    opacity: 0.5;
    pointer-events: none;
}

.loader {
    width: 100%;
    height: 100%;
    max-width: 35px;
    max-height: 35px;
    border: 5px solid var(--primary-lighten-3);
    border-bottom-color: var(--primary);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.categories-container-grid {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
    display: flex;
    gap: 20px;

    .category-block {
        gap: 10px;
        cursor: pointer;
        box-shadow: 0px 10px 10px -8px rgb(0 0 0 / 15%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include handleAllBreakpoints("box-shadow", "categoryBlockShadow", "0px 4px 6px rgba(0, 0, 0, 0.1)");
        @include handleAllBreakpoints("width", "categoryBlockWidth", "45%::30%");
        @include handleAllBreakpoints("padding", "categoryBlockPadding", "15px");
        @include handleAllBreakpoints("background", "categoryBlockBackgroundColor", "#fff");
        @include handleAllBreakpoints("border", "categoryBlockBorder", "1px solid #999");
        @include handleAllBreakpoints("border-radius", "categoryBlockBorderRadius", "5px");

        > .category-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            @include handleAllBreakpoints("width", "categoryIconWidth", "60px");
            @include handleAllBreakpoints("height", "categoryIconHeight", "60px");
            @include handleAllBreakpoints("min-width", "categoryIconWidth", "60px");
            @include handleAllBreakpoints("min-height", "categoryIconHeight", "60px");
            @include handleAllBreakpoints("filter", "categoryIconFilter", "unset");
            img {
                width: 100%;
            }
        }

        .category-content {
            gap: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .category-title {
                @include handleAllBreakpoints("font-size", "categoryBlockTitleSize", "14px::16px");
                @include handleAllBreakpoints("font-weight", "categoryBlockTitleWeight", "500::700");
                @include handleAllBreakpoints("color", "categoryBlockTitleColor", "#000");
                @include handleAllBreakpoints("text-align", "categoryBlockTitleAlign", "center");
            }
            .category-description {
                @include handleAllBreakpoints("font-size", "categoryBlockDescriptionSize", "12px::14px");
                @include handleAllBreakpoints("font-weight", "categoryBlockDescriptionWeight", "300");
                @include handleAllBreakpoints("color", "categoryBlockDescriptionColor", "#000");
                @include handleAllBreakpoints("text-align", "categoryBlockDescriptionAlign", "center");
            }
        }
        .caret {
            display: none;
        }
        &:hover {
            @include handleAllBreakpoints(
                "background",
                "categoryBlockHoverBackgroundColor",
                "var(--global-sections-buttons-background-color)"
            );
            @include handleAllBreakpoints(
                "color",
                "categoryBlockHoverTextColor",
                "var(--global-sections-buttons-text-color)"
            );
            @include handleAllBreakpoints("box-shadow", "categoryBlockHoverShadow", "0px 4px 6px rgba(0, 0, 0, 0.1)");

            .category-title {
                @include handleAllBreakpoints("color", "categoryBlockTitleHoverColor", "#fff");
            }
            .category-description {
                @include handleAllBreakpoints("color", "categoryBlockDescriptionHoverColor", "#fff");
            }
            .category-icon {
                @include handleAllBreakpoints("filter", "categoryIconHoverFilter", "brightness(0) invert(1)");
            }
        }
    }

    .loader-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        @include handleAllBreakpoints("width", "categoryIconWidth", "60px");
        @include handleAllBreakpoints("height", "categoryIconHeight", "60px");
        @include handleAllBreakpoints("min-width", "categoryIconWidth", "60px");
        @include handleAllBreakpoints("min-height", "categoryIconHeight", "60px");
    }
}

.categories-container-list {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
    display: flex;

    .category-block {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
        @include handleAllBreakpoints("padding", "categoryBlockPadding", "20px ");
        @include handleAllBreakpoints("width", "categoryBlockWidth", "100%");
        @include handleAllBreakpoints("border", "categoryBlockBorder", "1px solid #999");
        @include handleAllBreakpoints("border-radius", "categoryBlockBorderRadius", "5px");
        @include handleAllBreakpoints(
            "background",
            "categoryBlockBackgroundColor",
            "linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%)"
        );
        @include handleAllBreakpoints("box-shadow", "categoryBlockShadow", "0px 4px 6px rgba(0, 0, 0, 0.1)");

        border: 1px solid #d1d1d1;
        border-radius: 5px;

        &:hover {
            @include handleAllBreakpoints(
                "background",
                "categoryBlockHoverBackgroundColor",
                "var(--primary-lighten-5)"
            );
            @include handleAllBreakpoints("box-shadow", "categoryBlockHoverShadow", "0px 4px 6px rgba(0, 0, 0, 0.1)");
            .category-title {
                @include handleAllBreakpoints("color", "categoryBlockTitleHoverColor");
            }
            .category-title {
                @include handleAllBreakpoints("color", "categoryBlockDescriptionHoverColor");
            }
            .category-icon {
                @include handleAllBreakpoints("filter", "categoryIconHoverFilter", "unset");
            }
            cursor: pointer;
        }
        .caret {
            margin-left: auto;
            width: 20px;
            min-width: 20px;
        }

        > .category-icon {
            margin-bottom: 0;
            top: 20px;
            left: 20px;
            position: absolute;

            img {
                @include handleAllBreakpoints("width", "categoryIconWidth", "20px::60px");
                @include handleAllBreakpoints("height", "categoryIconHeight", "20px::60px");
                @include handleAllBreakpoints("min-width", "categoryIconWidth", "20px::60px");
                @include handleAllBreakpoints("min-height", "categoryIconHeight", "20px::60px");
                @include handleAllBreakpoints("filter", "categoryIconFilter", "unset");
            }

            @include md {
                position: unset;
                margin-right: 15px;
                border-right: 1px solid #000;
                padding-right: 15px;
                justify-content: center;
                align-items: center;
            }
        }
        > .category-content {
            .category-title {
                font-size: 16px;
                font-weight: 700;
                margin-left: 25px;
                @include md {
                    margin-left: 0;
                }

                @include handleAllBreakpoints("font-size", "categoryBlockTitleSize", "14px::16px");
                @include handleAllBreakpoints("font-weight", "categoryBlockTitleWeight", "500::700");
                @include handleAllBreakpoints("color", "categoryBlockTitleColor", "#000");
                @include handleAllBreakpoints("text-align", "categoryBlockTitleAlign", "left");
            }
            .category-description {
                @include handleAllBreakpoints("font-size", "categoryBlockDescriptionSize", "12px::14px");
                @include handleAllBreakpoints("font-weight", "categoryBlockDescriptionWeight", "300");
                @include handleAllBreakpoints("color", "categoryBlockDescriptionColor", "#000");
                @include handleAllBreakpoints("text-align", "categoryBlockDescriptionAlign", "left");
            }
            gap: 5px;
            display: flex;
            flex-direction: column;
        }
    }

    .loader-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        @include handleAllBreakpoints("width", "categoryIconWidth", "20px::60px");
        @include handleAllBreakpoints("height", "categoryIconHeight", "20px::60px");
        @include handleAllBreakpoints("min-width", "categoryIconWidth", "20px::60px");
        @include handleAllBreakpoints("min-height", "categoryIconHeight", "20px::60px");
    }
}
