
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

/****************************** Common ******************************/
.steps-inner {
    flex-grow: 1;
    padding-bottom: 20px;
}

h1 {
    &.step-title {
        // special case for first step title
        @include handleAllBreakpoints(
            "font-size",
            "firstStepTitleSize",
            "24px::var(--global-form-typography-firstStepTitle-size)",
            "!important"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "firstStepTitleWeight",
            "var(--global-form-typography-firstStepTitle-weight)",
            "!important"
        );
        @include handleAllBreakpoints(
            "color",
            "firstStepTitleColor",
            "var(--global-form-typography-firstStepTitle-color)",
            "!important"
        );
        @include handleAllBreakpoints(
            "text-align",
            "firstStepTitleAlign",
            "var(--global-form-typography-firstStepTitle-align)",
            "!important"
        );
    }
}
.form-wrapper {
    @include handleAllBreakpoints("justify-content", "formPosition", "center");
}
.step-title {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: black;
    line-height: 1.3;

    @include handleAllBreakpoints(
        "font-size",
        "stepTitleSize",
        "var(--global-form-typography-title-size)",
        "!important"
    );
    @include handleAllBreakpoints(
        "font-weight",
        "stepTitleWeight",
        "var(--global-form-typography-title-weight)",
        "!important"
    );
    @include handleAllBreakpoints(
        "color",
        "stepTitleColor",
        "var(--global-form-typography-title-color)",
        "!important"
    );
    @include handleAllBreakpoints(
        "text-align",
        "stepTitleAlign",
        "var(--global-form-typography-title-align)",
        "!important"
    );
}

.label-as-step-title {
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    margin-top: -20px;
    margin-bottom: 25px !important;
    color: black;
    font-weight: 700;
    line-height: 1.3;

    @include handleAllBreakpoints(
        "font-size",
        "stepTitleSize",
        "var(--global-form-typography-title-size)",
        "!important"
    );
    @include handleAllBreakpoints(
        "font-weight",
        "stepTitleWeight",
        "var(--global-form-typography-title-weight)",
        "!important"
    );
    @include handleAllBreakpoints(
        "color",
        "stepTitleColor",
        "var(--global-form-typography-title-color)",
        "!important"
    );
    @include handleAllBreakpoints(
        "text-align",
        "stepTitleAlign",
        "var(--global-form-typography-title-align)",
        "!important"
    );
}

.step-description {
    text-align: left;
    font-weight: 300;
    margin-bottom: 2rem;
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }

    @include handleAllBreakpoints(
        "font-size",
        "stepDescriptionSize",
        "var(--global-form-typography-description-size)"
    );
    @include handleAllBreakpoints(
        "font-weight",
        "stepDescriptionWeight",
        "var(--global-form-typography-description-weight)"
    );
    @include handleAllBreakpoints(
        "color",
        "stepDescriptionColor",
        "var(--global-form-typography-description-color)"
    );
    @include handleAllBreakpoints(
        "text-align",
        "stepDescriptionAlign",
        "var(--global-form-typography-description-align)"
    );
}

.fields-wrapper {
    margin-top: 1.5rem !important;
    margin: 0 auto;
    @include handleAllBreakpoints(
        "max-width",
        "fieldWrapperMaxWidth",
        "var(--global-form-others-all-max-width)"
    );
}

.field-note {
    margin-left: 7px;
    @include handleAllBreakpoints(
        "color",
        "fieldNoteTextColor",
        "#999",
        "!important"
    );
    @include handleAllBreakpoints(
        "font-size",
        "fieldNoteTextSize",
        "12px",
        "!important"
    );
}

.field-error-message {
    left: 7px;
}

.tcpa {
    margin-bottom: 10px;

    @include handleAllBreakpoints(
        "color",
        "tcpaTextColor",
        "#242626",
        "!important"
    );
    @include handleAllBreakpoints(
        "font-weight",
        "tcpaTextWeight",
        "300",
        "!important"
    );
    @include handleAllBreakpoints(
        "font-size",
        "tcpaTextSize",
        "9px",
        "!important"
    );
    @include handleAllBreakpoints(
        "line-height",
        "tcpaTextLineHeight",
        "12px",
        "!important"
    );
}

.progress {
    & > div:first-child {
        span {
            @include handleAllBreakpoints("color", "progressTextColor", "#fff");
        }
        span:last-child {
            @include handleAllBreakpoints(
                "border-bottom-color",
                "progressTriangleColor::progressBackgroundColor",
                "var(--global-form-general-progress-color)"
            );
        }
        @include handleAllBreakpoints(
            "background-color",
            "progressBackgroundColor",
            "var(--global-form-general-progress-color)"
        );
    }

    & > div:last-child {
        @include handleAllBreakpoints(
            "background-color",
            "progressBarColor",
            "#eeeeee"
        );
        span {
            @include handleAllBreakpoints(
                "background-color",
                "topProgressBarColor::progressBackgroundColor",
                "var(--global-form-general-progress-color)"
            );
        }
    }
}
.back-button {
    @include handleAllBreakpoints(
        "color",
        "backButtonTextColor",
        "black",
        "!important"
    );
    @include handleAllBreakpoints(
        "font-weight",
        "backButtonTextWeight",
        "300",
        "!important"
    );
    @include handleAllBreakpoints(
        "font-size",
        "backButtonTextSize",
        "12px",
        "!important"
    );
    @include handleAllBreakpoints(
        "background-color",
        "backButtonBackgroundColor",
        "transparent",
        "!important"
    );
    @include handleAllBreakpoints(
        "padding",
        "backButtonTextPadding",
        "0",
        "!important"
    );
    @include handleAllBreakpoints(
        "border-radius",
        "backButtonBorderRadius",
        "0",
        "!important"
    );
    @include handleAllBreakpoints(
        "border",
        "backButtonBorder",
        "none",
        "!important"
    );
    @include handleAllBreakpoints(
        "box-shadow",
        "backButtonBoxShadow",
        "none",
        "!important"
    );
}
.thankYouStyle h2,
.thankYouStyle p {
    @include handleAllBreakpoints(
        "color",
        "thankYouTextColor",
        "black",
        "!important"
    );
}
.thankYouStyle {
    img,
    svg {
        @include handleAllBreakpoints("filter", "thankYouFilter");
    }
}
/****************************** Default Variant ******************************/
.form {
    z-index: 5;
    width: 100%;
    min-height: 500px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;

    @include handleAllBreakpoints(
        "padding",
        "formPadding",
        "30px 24px::30px 24px::40px 40px"
    );
    @include handleAllBreakpoints(
        "border-radius",
        "formBorderRadius",
        "var(--global-form-general-form-border-radius)"
    );
    @include handleAllBreakpoints(
        "max-width",
        "formMaxWidth",
        "var(--global-form-general-max-width)"
    );
    @include handleAllBreakpoints(
        "background-color",
        "formBackgroundColor",
        "var(--global-form-general-form-background-color)"
    );
    @include handleAllBreakpoints(
        "border",
        "formBorder",
        "var(--global-form-general-form-border)"
    );
    @include handleAllBreakpoints(
        "box-shadow",
        "formShadow",
        "var(--global-form-general-form-box-shadow)"
    );

    .form-button {
        margin: 0 auto;
        margin-top: 5px;
        background-color: black;
        transition: all 0.2s ease-in-out;
        border-radius: 3px;
        color: white;

        svg {
            path {
                @include handleAllBreakpoints(
                    "fill",
                    "buttonTextColor",
                    "var(--global-form-buttons-text-color)"
                );
            }
        }

        &:hover {
            @include handleAllBreakpoints(
                "box-shadow",
                "buttonHoverShadow",
                "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px"
            );
            @include handleAllBreakpoints(
                "opacity",
                "buttonHoverOpacity",
                "0.8"
            );
            @include handleAllBreakpoints(
                "background",
                "buttonHoverBackgroundColor::buttonBackgroundColor",
                "var(--global-form-buttons-background-color)",
                "!important"
            );
        }

        @include handleAllBreakpoints(
            "background",
            "buttonBackgroundColor",
            "var(--global-form-buttons-background-color)",
            "!important"
        );
        @include handleAllBreakpoints(
            "box-shadow",
            "buttonShadow",
            "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px"
        );
        @include handleAllBreakpoints(
            "border",
            "buttonBorder",
            "var(--global-form-buttons-border)"
        );
        @include handleAllBreakpoints(
            "border-radius",
            "buttonBorderRadius",
            "var(--global-form-buttons-border-radius)"
        );
        @include handleAllBreakpoints(
            "color",
            "buttonTextColor",
            "var(--global-form-buttons-text-color)"
        );
        @include handleAllBreakpoints(
            "max-width",
            "fieldWrapperMaxWidth",
            "var(--global-form-others-all-max-width)"
        );
        @include handleAllBreakpoints(
            "max-width",
            "buttonMaxWidth",
            "var(--global-form-others-all-max-width)"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "buttonTextWeight",
            "var(--global-form-buttons-text-weight, 500)"
        );
        @include handleAllBreakpoints(
            "font-size",
            "buttonTextSize",
            "var(--global-form-buttons-text-size, 16px)"
        );
    }
    .form-field {
        max-width: unset;
        margin-bottom: 0.5rem;
        width: auto;
        margin: 0 auto;
        @include handleAllBreakpoints("width", "fieldWidth", "auto");

        & > label {
            @include handleAllBreakpoints(
                "font-size",
                "fieldLabelSize",
                "var(--global-form-typography-fieldLabel-size)"
            );
            @include handleAllBreakpoints(
                "font-weight",
                "fieldLabelWeight",
                "var(--global-form-typography-fieldLabel-weight)"
            );
            @include handleAllBreakpoints(
                "color",
                "fieldLabelColor",
                "var(--global-form-typography-fieldLabel-color)"
            );
            @include handleAllBreakpoints(
                "text-align",
                "fieldLabelAlign",
                "var(--global-form-typography-fieldLabel-align)"
            );
        }
        input,
        select,
        [class*="streetAddressField"] {
            // margin: 0 auto;
            @include handleAllBreakpoints(
                "border-radius",
                "fieldBorderRadius",
                "var(--global-form-others-all-border-radius)"
            );
        }
        input,
        [class*="streetAddressFieldWrapper"] {
            @include handleAllBreakpoints("width", "inputWidth", "100%");
        }
        select {
            @include handleAllBreakpoints("width", "selectWidth", "100%");
        }
    }
    .radio-field {
        display: flex;
        @include handleAllBreakpoints(
            "flex-direction",
            "radioOptionsDirection",
            "row"
        );
        flex-wrap: wrap;
        margin-top: 10px;
        padding: 10px;
        @include handleAllBreakpoints("gap", "radioGap", "10px");
        & > div {
            @include handleAllBreakpoints("width", "radioOptionWidth", "100%");
            @include handleAllBreakpoints(
                "min-height",
                "radioOptionHeight",
                "auto"
            );
            margin-bottom: 0px;
            display: flex;
            input {
                &:checked + label {
                    > div {
                        img {
                            filter: var(
                                --global-form-radio-selected-icon-filter
                            );
                        }
                    }
                    @include handleAllBreakpoints(
                        "background",
                        "radioSelectedBackgroundColor",
                        "var(--global-form-radio-selected-background-color)"
                    );
                    @include handleAllBreakpoints(
                        "border",
                        "radioSelectedBorder",
                        "var(--global-form-radio-selected-border)"
                    );
                    @include handleAllBreakpoints(
                        "color",
                        "radioSelectedTextColor",
                        "var(--global-form-radio-selected-text-color)"
                    );
                    @include handleAllBreakpoints(
                        "font-weight",
                        "radioSelectedWeight",
                        "var(--global-form-radio-selected-text-weight)"
                    );
                }
            }
            label {
                border-radius: 4px;
                transition: all 0.2s ease-in-out;
                display: flex;
                align-items: center;
                min-height: 50px;
                margin: 0 auto;
                gap: 10px;

                @include handleAllBreakpoints(
                    "flex-direction",
                    "radioOptionDirection",
                    "row"
                );
                @include handleAllBreakpoints(
                    "justify-content",
                    "optionAlignment",
                    "center"
                );
                @include handleAllBreakpoints("max-width", "radioMaxWidth");
                @include handleAllBreakpoints(
                    "font-weight",
                    "radioWeight",
                    "var(--global-form-radio-normal-text-weight)"
                );
                @include handleAllBreakpoints(
                    "font-size",
                    "radioFontSize",
                    ".875rem",
                    "!important"
                );
                span {
                    @include handleAllBreakpoints(
                        "font-size",
                        "radioFontSize",
                        ".875rem",
                        "!important"
                    );
                }

                padding: 11px 16px;
                span {
                    margin-top: 0;
                }
                > div {
                    img {
                        @include handleAllBreakpoints(
                            "width",
                            "radioIconWidth",
                            "30px"
                        );
                        @include handleAllBreakpoints(
                            "height",
                            "radioIconHeight",
                            "auto"
                        );
                        @include handleAllBreakpoints(
                            "max-width",
                            "radioIconMaxWidth",
                            "30px"
                        );

                        filter: var(--global-form-radio-normal-icon-filter);
                    }
                }
                &:hover {
                    opacity: 0.8;
                    @include handleAllBreakpoints(
                        "opacity",
                        "radioHoverOpacity",
                        "0.8"
                    );
                    > div {
                        img {
                            filter: var(
                                --global-form-radio-hovered-icon-filter
                            );
                        }
                    }

                    @include handleAllBreakpoints(
                        "background",
                        "radioHoverBackgroundColor",
                        "var(--global-form-radio-hovered-background-color)"
                    );
                    @include handleAllBreakpoints(
                        "border",
                        "radioHoverBorder",
                        "var(--global-form-radio-hovered-border)"
                    );
                    @include handleAllBreakpoints(
                        "color",
                        "radioHoverTextColor",
                        "var(--global-form-radio-hovered-text-color)"
                    );
                    @include handleAllBreakpoints(
                        "font-weight",
                        "radioHoverWeight",
                        "var(--global-form-radio-hovered-text-weight)"
                    );
                    @include handleAllBreakpoints(
                        "box-shadow",
                        "radioHoverShadow"
                    );
                }

                /* radio inputs inherit button styles by default */
                @include handleAllBreakpoints(
                    "background",
                    "buttonBackgroundColor",
                    "var(--global-form-buttons-background-color)"
                );
                @include handleAllBreakpoints(
                    "border",
                    "buttonBorder",
                    "var(--global-form-buttons-border)"
                );
                @include handleAllBreakpoints(
                    "border-radius",
                    "buttonBorderRadius",
                    "var(--global-form-buttons-border-radius)"
                );
                @include handleAllBreakpoints(
                    "color",
                    "buttonTextColor",
                    "var(--global-form-buttons-text-color)"
                );

                /* radio styles override button styles */
                @include handleAllBreakpoints(
                    "background",
                    "radioBackgroundColor",
                    "var(--global-form-radio-normal-background-color)"
                );
                @include handleAllBreakpoints(
                    "border",
                    "radioBorder",
                    "var(--global-form-radio-normal-border)"
                );
                @include handleAllBreakpoints(
                    "color",
                    "radioTextColor",
                    "var(--global-form-radio-normal-text-color)"
                );
                @include handleAllBreakpoints(
                    "border-radius",
                    "radioBorderRadius",
                    "var(--global-form-others-all-border-radius)"
                );
                @include handleAllBreakpoints("box-shadow", "radioShadow");
            }
            // @include lg {
            //     margin-bottom: 15px;
            // }
        }
    }
    .checkbox-field {
        & > div {
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            input {
                &:checked + label {
                    @include handleAllBreakpoints(
                        "background",
                        "checkboxSelectedBackgroundColor",
                        "var(--global-form-checkbox-selected-background-color)"
                    );
                    @include handleAllBreakpoints(
                        "border",
                        "checkboxSelectedBorder",
                        "var(--global-form-checkbox-selected-border)"
                    );
                    @include handleAllBreakpoints(
                        "color",
                        "checkboxSelectedTextColor",
                        "var(--global-form-checkbox-selected-text-color)"
                    );
                    @include handleAllBreakpoints(
                        "font-weight",
                        "checkboxSelectedWeight",
                        "var(--global-form-checkbox-selected-text-weight)"
                    );

                    span {
                        &:first-child {
                            @include handleAllBreakpoints(
                                "background",
                                "checkboxTicBoxBackgroundColor::checkboxSelectedBackgroundColor",
                                "var(--global-form-checkbox-normal-check-background-color)"
                            );
                        }
                    }
                }
            }
            label {
                border-radius: 3px;
                @include handleAllBreakpoints(
                    "font-size",
                    "checkboxFontSize",
                    "14px"
                );
                display: flex;
                align-items: center;
                @include handleAllBreakpoints("max-width", "checkboxMaxWidth");
                margin: 0 auto;
                text-align: left;
                @include handleAllBreakpoints(
                    "font-weight",
                    "checkboxWeight",
                    "var(--global-form-checkbox-normal-text-weight)"
                );

                @include md {
                    min-height: 50px;
                }

                span {
                    @include handleAllBreakpoints(
                        "font-size",
                        "checkboxFontSize",
                        "14px"
                    );
                    &:first-child {
                        background-color: white;
                        @include handleAllBreakpoints(
                            "border",
                            "checkboxTicBoxBorder",
                            "1px solid black"
                        );
                        svg {
                            path {
                                @include handleAllBreakpoints(
                                    "stroke",
                                    "checkboxTicBoxColor",
                                    "white"
                                );
                            }
                        }
                    }
                }

                &:hover {
                    opacity: 0.8;
                    @include handleAllBreakpoints(
                        "opacity",
                        "checkboxHoverOpacity",
                        "0.8"
                    );
                    @include handleAllBreakpoints(
                        "background",
                        "checkboxHoverBackgroundColor",
                        "var(--global-form-checkbox-hovered-background-color)"
                    );
                    @include handleAllBreakpoints(
                        "border",
                        "checkboxHoverBorder",
                        "var(--global-form-checkbox-hovered-border)"
                    );
                    @include handleAllBreakpoints(
                        "color",
                        "checkboxHoverTextColor",
                        "var(--global-form-checkbox-hovered-text-color)"
                    );
                    @include handleAllBreakpoints(
                        "font-weight",
                        "checkboxHoverWeight",
                        "var(--global-form-checkbox-hovered-text-weight)"
                    );
                }

                /* radio inputs inherit button styles by default */
                @include handleAllBreakpoints(
                    "background",
                    "buttonBackgroundColor",
                    "var(--global-form-buttons-background-color)"
                );
                @include handleAllBreakpoints(
                    "border",
                    "buttonBorder",
                    "var(--global-form-buttons-border)"
                );
                @include handleAllBreakpoints(
                    "border-radius",
                    "buttonBorderRadius",
                    "var(--global-form-buttons-border-radius)"
                );
                @include handleAllBreakpoints(
                    "color",
                    "buttonTextColor",
                    "var(--global-form-buttons-text-color)"
                );

                /* radio styles override button styles */
                @include handleAllBreakpoints(
                    "background",
                    "checkboxBackgroundColor",
                    "var(--global-form-checkbox-normal-background-color)"
                );
                @include handleAllBreakpoints(
                    "border",
                    "checkboxBorder",
                    "var(--global-form-checkbox-normal-border)"
                );
                @include handleAllBreakpoints(
                    "color",
                    "checkboxTextColor",
                    "var(--global-form-checkbox-normal-text-color)"
                );
                @include handleAllBreakpoints(
                    "border-radius",
                    "checkboxBorderRadius",
                    "var(--global-form-others-all-border-radius)"
                );
            }
        }
    }
    .date-picker {
        @include lg {
            background-color: transparent !important;
            > select {
                @include handleAllBreakpoints(
                    "color",
                    "datePickerTextColor",
                    "black",
                    "!important"
                );
                @include handleAllBreakpoints(
                    "background-color",
                    "datePickerBackgroundColor"
                );
            }
        }
    }
    .range-field {
        // border: 1px solid #ccc;
        // margin: 0 auto;
    }

    .field {
        &:not(.radio-field):not(.checkbox-field):not(.range-field) {
            background-color: white;
        }
    }

    @include lg {
        min-width: 300px;
    }

    .selling-point {
        font-size: 11px;
        color: #999;
        padding-top: 10px;
        font-style: italic;
        @include handleAllBreakpoints(
            "font-size",
            "sellingPointTextSize",
            "11px"
        );
        @include handleAllBreakpoints("color", "sellingPointTextColor", "#999");
        @include handleAllBreakpoints(
            "font-style",
            "sellingPointFontStyle",
            "italic"
        );
    }

    .step:first-child {
        .form-field {
            .field-label {
                @include handleAllBreakpoints(
                    "text-align",
                    "firstStepFieldLabelAlign",
                    "var(--global-form-typography-fieldLabel-align)"
                );
                @include handleAllBreakpoints(
                    "font-size",
                    "firstStepFieldLabelSize",
                    "var(--global-form-typography-fieldLabel-size)"
                );
                @include handleAllBreakpoints(
                    "font-weight",
                    "firstStepFieldLabelWeight",
                    "var(--global-form-typography-fieldLabel-weight)"
                );
                @include handleAllBreakpoints(
                    "color",
                    "firstStepFieldLabelColor",
                    "var(--global-form-typography-fieldLabel-color)"
                );
            }
        }
        .step-description {
            @include handleAllBreakpoints(
                "font-size",
                "firstStepDescriptionSize::stepDescriptionSize",
                "var(--global-form-typography-description-size)"
            );
            @include handleAllBreakpoints(
                "font-weight",
                "firstStepDescriptionWeight::stepDescriptionWeight",
                "var(--global-form-typography-description-weight)"
            );
            @include handleAllBreakpoints(
                "color",
                "firstStepDescriptionColor::stepDescriptionColor",
                "var(--global-form-typography-description-color)"
            );
            @include handleAllBreakpoints(
                "text-align",
                "firstStepDescriptionAlign::stepDescriptionAlign",
                "var(--global-form-typography-description-align)"
            );
        }
    }

    [class*="labelWithIcon"] {
        @include handleAllBreakpoints(
            "justify-content",
            "optionWithIconAlignment",
            "flex-start",
            "!important"
        );
    }
}
/****************************** Default Variant ******************************/

.offersContainer {
    background: transparent !important;
}
